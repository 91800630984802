// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import HowTo from 'components/Contentful/HowTo'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Erfahrungsbericht von Familie Marti"
        meta={[
          { name: 'description', content: 'Photovoltaik-Anlage &amp; Wärmepumpe anstatt einer Ölheizung ➤ Erfahrungsbericht der Familie Marti ✔️ Heizungsersatz ✔️ Umbau ✔️ Hier informieren! ' },
          { name: 'keywords', content: 'Erfahrungsbericht Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='76Wu4w76RgzMMw9Tae6i1M-RRA0IN92a0oJ7fVBXihgc'
          id='familie-marti-kallnach-be'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "RRA0IN92a0oJ7fVBXihgc",
    "updatedAt": "2022-07-21T07:08:43.859Z",
    "title": "FAMILIE MARTI, KALLNACH BE",
    "size": "Groß",
    "teaser": "22 Jahre lang war die alte Ölheizung der Familie Marti im Einsatz. Nachdem im Jahr 2019 eine Photovoltaik-Anlage eingezogen war, kam auch die Zeit für eine passende Heizungslösung. Die Anforderungen an die neue Heizung waren klar: Der produzierte Solarstrom soll zeitgleich vor Ort verbraucht werden.",
    "image": {
        "description": "Titelbild Hero Referenz-Story Familie Marti",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/5yvaeYdUkfw2gCe7fIUd0X/7e13f96e9f04a945ff9445c76ee41eb3/Hero-Bild.png"
    },
    "links": [
        {
            "type": "link",
            "id": "5Rv4CldRK0aI4UGakKsISC",
            "updatedAt": "2022-04-01T07:44:51.656Z",
            "label": "Zur Referenz-Übersicht",
            "page": {
                "slug": "referenzen"
            }
        }
    ],
    "anchorName": "familie-marti-kallnach-be"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='76Wu4w76RgzMMw9Tae6i1M-yl0fDGxX8dCQFW32b1khE'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "yl0fDGxX8dCQFW32b1khE",
    "updatedAt": "2021-05-05T09:21:41.424Z",
    "title": "«Nach 22 Jahren Ölheizung war es Zeit für eine bessere Heizlösung»",
    "steps": [
        {
            "type": "howToStep",
            "id": "5rbrvRtg0wm1GEkX4rmtlk",
            "updatedAt": "2021-06-11T07:41:34.855Z",
            "title": "Der neue Heizungsersatz sollte eine Wärmepumpe werden.",
            "description": "<p>In fast der Hälfte aller Schweizer Haushalte befindet sich eine Ölheizung. Früher war diese <a href=\"https://www.heizungsmacher.ch/wissen/wie-hoch-sind-die-kosten-einer-waermepumpe/#der-vergleich-von-verschiedenen-heizvarianten\">Heizvariante</a> im Markt die einfachste und günstigste. <strong>Auf dem neuesten Stand ist diese Heizlösung jedoch schon lange nicht mehr</strong>. Denn die <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">Ölheizung</a> arbeitet mit <strong>fossilen und begrenzten Brennstoffen</strong>. Um dieser umweltbelastenden Heizvariante entgegen zu wirken, <strong>erhöht der Bund regelmässig die <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/#steigende-co2-abgaben-hohe-betriebskosten\">CO2 Abgaben</a></strong> und schafft Anreize für den Umstieg auf erneuerbare Energien. Längerfristig betrachtet, macht sich der Hauseigentümer weder seinem Geldbeutel noch der Umwelt einen Gefallen. Nach einer Recherche von Familie Marti hat sich demnach schnell herausgestellt: <strong>Der Schlüssel zur Eigenverbrauchsoptimierung ist die Kombination von <a href=\"https://www.heizungsmacher.ch/wissen/waermepumpe-und-photovoltaik-ein-wirtschaftliches-duo/\">Wärmepumpe &#x26; Photovoltaik</a></strong>. Der neue <a href=\"https://www.heizungsmacher.ch/heizungsersatz/\">Heizungsersatz</a> sollte also eine <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpe</a> werden.</p>\n",
            "image": {
                "description": "ein Foto von der alten Ölheizung der Familie Marti",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5EcCgIdQTI9WALYcPZcVUc/451641bee771eeb9fb44d51361459e7a/2012---Bild-3.png"
            }
        },
        {
            "type": "howToStep",
            "id": "1IbuAwTRrYG6GVCD9mIAVH",
            "updatedAt": "2021-05-06T06:48:30.390Z",
            "title": "Wieso entschied sich Familie Marti für die Heizungsmacher AG?",
            "description": "<p>Emanuel Marti erklärt: «Als wir uns im Internet schlau gemacht haben, ist uns aufgefallen, dass es viele einzelne Anbieter gibt, welche vereinzelte Arbeiten übernehmen. Die frische und sympathische <a href=\"https://www.heizungsmacher.ch/\">Website</a> der Heizungsmacher AG ist uns jedoch direkt aufgefallen und hat uns vom ersten Moment an überzeugt. Alle notwendigen Informationen wurden uns auf Anhieb geliefert. <strong>Die Komplett-Lösung der Heizungsmacher AG</strong>, bei der auch <a href=\"https://www.heizungsmacher.ch/foerdergelder/\">der administrative und rechtliche Aufwand</a> übernommen wird, war sehr einladend. Nach der ersten <a href=\"https://www.heizungsmacher.ch/kontakt/\">kostenlosen Beratung</a> hat sich dann alles automatisch ergeben. <strong>Wir waren überzeugt: Die Heizungsmacher AG soll unsere Ölheizung durch eine Wärmepumpe ersetzen</strong>.»</p>\n",
            "image": {
                "description": "Standortanalyse Wärmepumpe",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/H7MjxIwykEOZYZ1BvxV7R/059e0386ee05028b0ed402114b45e252/2012---Bild-2.png"
            }
        },
        {
            "type": "howToStep",
            "id": "6hHs3DXUZGFDDPlmgt06it",
            "updatedAt": "2021-05-06T06:49:16.063Z",
            "title": "Familie Martis Erfahrung während der Bauarbeiten",
            "description": "<p>Emanuel Marti: «Wir haben den Umbau mit ruhigem Gewissen den <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">Heizungsmachern</a> überlassen. Die Bauarbeiten wurden sehr <strong>professionell und transparent</strong> ausgeführt. Die <strong>Kommunikation war hervorragend</strong> – so wussten wir immer über den aktuellen Stand der Dinge Bescheid.» Er fügt hinzu: «Während der Bauarbeiten kam es aufgrund eines Hindernisses an der Anlage zu einer kurzfristigen Verzögerung. Nachdem die Ursache ausfindig gemacht werden konnte, wurde das Problem rasch und zufriedenstellend gelöst.» Auch hier hat sich Familie Marti über die <strong>ehrliche und rasche Kommunikationskultur</strong> der Mitarbeiter gefreut.</p>\n",
            "image": {
                "description": "Ein Foto von der neuen Wärmepumpe der Familie Marti",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/35UfNfP1y4C9hGuTQFwsak/88ee9195277b15dd09989fa3f6e6c30f/IMG_2730.png"
            }
        },
        {
            "type": "howToStep",
            "id": "cVliL8qXrlWaf35NmEopw",
            "updatedAt": "2021-06-11T07:42:13.583Z",
            "title": "Wie ging es nach dem Umbau weiter?",
            "description": "<p>«Schon während der Bauarbeiten gefiel uns die kompetente und transparente Betreuung und genau so ging es auch nach dem Umbau weiter. Die <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">Mitarbeiter</a> besitzen das Know-how um auch bei technischen Fragen die Stellung halten und helfen zu können.»</p>\n<p><strong>Dank der <a href=\"https://www.heizungsmacher.ch/wissen/waermepumpe-und-photovoltaik-ein-wirtschaftliches-duo/\">Synergie von Wärmepumpe und Photovoltaik</a> darf Familie Marti nun auch unabhängiger vom Netzstrom leben und sich über geringe <a href=\"https://www.heizungsmacher.ch/wissen/wie-hoch-sind-die-kosten-einer-waermepumpe/#die-kosten-einer-waermepumpe-auf-einen-blick\">Heiz- und Betriebskosten</a> und die weiteren Vorteile dieser Kombination freuen</strong>.</p>\n<p>Besonders gefreut hat sich Familie Marti darüber, <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/#mehr-raum-durch-platzsparende-ersatz-loesungen\">dass der Umbau so viel mehr Raum geschaffen hat</a>. Auf unsere Frage, ob sie schon eine Vorstellung haben, was mit dem frisch gewonnen Platz gemacht werden könne, antwortet Emanuel Marti schmunzelnd: <strong>«Wir überlegen uns nun eine Sauna einbauen zu lassen</strong>.»</p>\n",
            "image": {
                "description": "Ein Foto vom neuem Boiler der Familie Marti",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7io5nZWAVXO962yOzVrqMf/016b6b75f86a1e0d6b9a2bfa59a56e05/2012---Bild-1.png"
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='76Wu4w76RgzMMw9Tae6i1M-eKs0obuGgpg9nPJC37ORV'
          id='feedback-familie-marti'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "eKs0obuGgpg9nPJC37ORV",
    "updatedAt": "2022-09-20T12:11:53.537Z",
    "title": "Feedback Familie Marti",
    "anchorName": "feedback-familie-marti",
    "text": "<p><em>„Die Qualität einer Lösung zeigt sich auch dann, wenn das Projekt selbst bereits abgeschlossen ist. Heizungsmacher steht uns bei Fragen auch jetzt stets offen, unkompliziert und lösungsorientiert zur Seite. Das schätzen wir sehr“</em></p>\n<p>Heizungsmacher-Team: <em>Sehr geehrter Herr Marti. Vielen Dank, dass Sie sich Zeit genommen haben uns zu bewerten. Es freut uns zu hören, dass Sie mit unserem Angebot und dem Resultat zufrieden waren.</em></p>\n",
    "image": {
        "description": "Kundenfeedback: 4 Sterne-Bewertung",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/h98XboTmTf6MDQjSVt3Pc/8f057f1f87afea8b6f16e8af9f9e58bb/4_Sterne-Bewertung.svg"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='76Wu4w76RgzMMw9Tae6i1M-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
